<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>门店管理</el-breadcrumb-item>
            <el-breadcrumb-item>门店列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input placeholder="请输入门店名称/门店电话" clearable v-model="queryInfo.query" @clear="getList()">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <el-select  v-model="queryInfo.query1" clearable  placeholder="请选择门店状态" @change="getList()"  style="margin-right:10px">
                            <el-option label="营业" value="1"></el-option>
                            <el-option label="关闭" value="2"></el-option>
                            <el-option label="装修中" value="3"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <el-button type="primary" @click="AddStore">添加门店</el-button>
                    <el-button type="primary" @click="SetBath">淋浴测试帐号</el-button>
                </el-col>
                <el-col :span="6" style="text-align: right;font-size: 12px;color:#999;line-height: 30px;">( *店名红色的是虚拟门店 )</el-col>
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--商品列表区域-->
            <el-table :data="list" style="width: 100%" stripe size="middle" border>
                <!-- <el-table-column label="ID" type="index"  fixed></el-table-column> -->
                <!-- <el-table-column label="门店ID" prop="id"  fixed></el-table-column> -->
                <el-table-column label="序号" width="50" fixed>
                    <template slot-scope="scope">
                        {{ (queryInfo.pagenum - 1) * queryInfo.pagesize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="门店名称" prop="storename" width="200" show-overflow-tooltip fixed>
                    <template slot-scope="scope">
                        <span :style="{ color: scope.row.ismock == '1' ? 'red' : 'black' }">
                            {{ scope.row.storename }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="所属城市" prop="cityid" width="200">
                    <template slot-scope="scope">
                        {{scope.row.city}} {{scope.row.county}}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="门店图片" prop="storepic" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.storepic">
                            <el-popover placement="bottom"  width="600" trigger="click">
                                <div style="text-align: center;">
                                <img :src="imgURL+'static/pics/'+scope.row.storepic"  style="width:600px"/>
                                </div>
                                <img  slot="reference" :src="imgURL+'static/pics/'+scope.row.storepic" style="width:30px">
                            </el-popover>
                        </div>
                    </template>
                </el-table-column> -->
                <el-table-column label="门店地址" prop="address" width="200" show-overflow-tooltip/>
                <el-table-column label="电话" prop="telephone" width="150"></el-table-column>
                <!-- <el-table-column label="经纬度" prop="sn" width="200">
                    <template slot-scope="scope">
                        {{ scope.row.lat }}--{{ scope.row.long }}
                    </template>
                </el-table-column> -->
                <el-table-column label="进店1Sn" prop="sn" width="100"/>
                <el-table-column label="出店1Sn" prop="outsn" width="100"/>
                <el-table-column label="进店2Sn" prop="sn1" width="100"/>
                <el-table-column label="出店2Sn" prop="outsn1" width="100"/>
                <el-table-column label="鱼眼摄像头1Sn" prop="fishsn" width="220"/>
                <el-table-column label="鱼眼摄像头2Sn" prop="fishsn1" width="220"/>
                <el-table-column label="摄像头1Sn" prop="camera1" width="190"/>
                <el-table-column label="摄像头2Sn" prop="camera2" width="190"/>
                <el-table-column label="摄像头3Sn" prop="camera3" width="190"/>
                <el-table-column label="摄像头4Sn" prop="camera3" width="190"/>
                <el-table-column label="边缘盒子Sn" prop="boxsn" width="190"/>
                <el-table-column label="温湿度Sn" prop="wsdsn" width="250"/>
                <el-table-column label="操房数量" prop="roomnum" width="100">
                    <template slot-scope="scope">
                        {{scope.row.roomnum}} 间
                    </template>
                </el-table-column>
                <el-table-column label="门店面积" prop="storearea" width="100">
                    <template slot-scope="scope">
                        {{scope.row.storearea}}  ㎡
                    </template>
                </el-table-column>
                <el-table-column label="器械种类" prop="devicetype" width="100">
                    <template slot-scope="scope">
                        {{scope.row.devicetype}}  种
                    </template>
                </el-table-column>
                <el-table-column label="微信" prop="wechat" width="150"></el-table-column>
                <el-table-column label="Wifi" prop="wifi" width="150"></el-table-column>
                <el-table-column label="抖音门店ID" prop="dystoreid" width="180"></el-table-column>
                <el-table-column label="加盟时间" prop="adddate" width="180"></el-table-column>
                <el-table-column label="收款帐户" prop="issub" width="120" fixed="right">
                    <template slot-scope="scope">
                        <span v-if="scope.row.issub == '0'">总部商户</span>
                        <span v-else>{{ scope.row.submchid }}</span>
                        
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="isopen" width="100" fixed="right">
                    <template slot-scope="scope">
                       <el-tag v-if="scope.row.isopen=='1'"  type="success">营业</el-tag>
                       <el-tag v-else-if="scope.row.isopen=='2'" type="info">关闭</el-tag>
                       <el-tag v-else-if="scope.row.isopen=='3'" type="warning">装修中</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="虚拟" prop="ismock" width="80"  fixed="right">
                    <template  slot-scope="scope">
                        <el-switch active-value="1" inactive-value="0" v-model="scope.row.ismock" @change="SetMock(scope.row.id,scope.row.ismock)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="美团授权" width="80"  fixed="right">
                    <template  slot-scope="scope">
                        <el-button type="warning" icon="el-icon-connection" size="mini" :disabled="scope.row.ismeituan" @click="SetMeiTuan(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
                <el-table-column label="人数" prop="count" width="80"  fixed="right">
                    <template  slot-scope="scope">
                        {{ scope.row.count }} 人
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="300" fixed="right">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="标注门店" placement="top">
                            <el-button type="warning" icon="el-icon-location" size="mini" @click="MarkStore(scope.row.id)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="修改信息" placement="top">
                            <el-button type="success" icon="el-icon-edit" size="mini" @click="EditStore(scope.row.id)"></el-button>
                        </el-tooltip>
                        <!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelStore(scope.row.id)"></el-button> -->
                        <el-tooltip class="item" effect="dark" content="设置店主" placement="top">
                            <el-button type="danger" icon="el-icon-user-solid" size="mini" @click="SetBoss(scope.row.id)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="设置收款商户及扣点" placement="top">
                            <el-button type="primary" icon="el-icon-s-tools" size="mini" @click="Setting(scope.row.id)"></el-button>
                        </el-tooltip>
                        <!-- <el-tooltip class="item" effect="dark" content="设置沐浴帐号" placement="top">
                            <el-button type="warning" icon="el-icon-setting" size="mini" @click="SetBath(scope.row.id)"></el-button>
                        </el-tooltip> -->
                    </template>
                </el-table-column>
            </el-table>
            <!--商品列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--设置店主对话框-->
            <el-dialog title="设置店主" :visible.sync="setDialogVisible" width="50%" @close="setDialogClosed">
                <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px" class="demo-dynamic">
                    <el-form-item
                        v-for="(owner, index) in dynamicValidateForm.owners"
                        :label="'店主' + (index + 1)"
                        :key="owner.key"
                        :prop="'owners.' + index + '.username'"
                        :rules="{ required: true, message: '店主不能为空', trigger: 'blur' }"
                    >
                        <el-input v-model="owner.username" placeholder="请输入店主手机号" style="width: 300px; margin:0 10px ;"></el-input>
                        <el-button @click.prevent="removeOwner(owner)">删除</el-button>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addOwner">新增店主</el-button>
                    <el-button type="primary" @click="submitForm('dynamicValidateForm')">提交</el-button>
                </span>
            </el-dialog>
            <!--设置店主对话框-->
            <!-- 设置商户及扣点 -->
            <el-dialog title="设置商户及扣点" :visible.sync="settingDialogVisible" width="50%" @close="settingDialogClosed">
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px" style="padding: 10px;">
                    <el-form-item label="店名" prop="storename">
                        <el-input v-model="editForm.storename" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="欠设备款" prop="alarm">
                        <el-input v-model="editForm.alarm" type="number" :min="1">
                            <template slot="append">元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="扣点" prop="points">
                        <el-input v-model="editForm.points">
                            <template slot="append">(例：0.05)</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="子商户收款" prop="issub">
                        <el-switch :active-value="'1'" :inactive-value="'0'" v-model="editForm.issub" @change="ChangeSub"></el-switch>
                    </el-form-item>
                    <el-form-item label="商户号" prop="submchid" v-if="editForm.issub === '1'">
                        <el-input v-model="editForm.submchid"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="settingDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="SettingSave">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 设置商户及扣点 -->
             <!-- 沐浴测试帐号 -->
            <el-dialog title="沐浴测试帐号" :visible.sync="bathDialogVisible" width="50%" @close="bath_username=''">
                <el-input placeholder="请输入内容手机号" v-model="bath_username" style="width: 40%;">
                    <el-button slot="append" @click="AddBath">添加</el-button>
                </el-input>
                <el-table :data="bathlist" style="width: 100%;height: 500px;" stripe size="middle">
                    <el-table-column label="ID" type="index"></el-table-column>
                    <el-table-column label="手机号" prop="username" width="150"></el-table-column>
                    <el-table-column label="Openid" prop="openid" width="300"></el-table-column>
                    <el-table-column label="添加日期" prop="adddate" width="300"></el-table-column>
                    <el-table-column label="删除" >
                        <template slot-scope="scope">
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelBath(scope.row.username)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
            <!-- 沐浴测试帐号 -->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return{
            imgURL:this.GLOBAL.imgURL,
            queryInfo: {
                query: '',
                query1:'',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            bath_username:'',
            bathlist:[],
            addDialogVisible:false,
            editDialogVisible:false,
            setDialogVisible:false,
            settingDialogVisible:false,
            bathDialogVisible:false,
            addForm:{
                 username:''
            },
             //编辑表单数据
             editForm:{},
            //添加表单验证规则
            addFormRules:{
                username:[
                    { required: true, message: '请输入门店名称', trigger: 'blur' }
                ]
            },
            editFormRules:{
                storename:[
                    { required: true, message: '请输入门店名称', trigger: 'blur' }
                ],
                alarm:[
                    { required: true, message: '请输入欠设备款', trigger: 'blur' }
                ],
                points: [
                    { required: true, message: '请输入总部扣点', trigger: 'blur' },
                    { 
                        validator: (rule, value, callback) => {
                            const numberValue = Number(value);
                            if (!value) {
                                callback(new Error('请输入总部扣点'));
                            } else if (isNaN(numberValue)) {
                                callback(new Error('必须是数字'));
                            } else if (numberValue < 0) {
                                callback(new Error('不能是负数'));
                            } else if (value.includes('.') && !value.startsWith('0.')) {
                                callback(new Error('格式不正确'));
                            } else {
                                callback(); // 验证通过
                            }
                        },
                        trigger: 'blur' 
                    },
                ],
                submchid: [
                    { required: true, message: '请输入子商户号', trigger: 'blur' },
                    { 
                        validator: (rule, value, callback) => {
                            const numberValue = Number(value);
                            if (!value) {
                                callback(new Error('请输入子商户号'));
                            } else if (isNaN(numberValue)) {
                                callback(new Error('子商户号必须是数字'));
                            } else if (value.length < 8 || value.length > 10) {
                                callback(new Error('子商户号必须是8到10位'));
                            } else {
                                callback(); // 验证通过
                            }
                        },
                        trigger: 'blur' 
                    },
                ],


            },
            storeid:"",
            dynamicValidateForm: {
                owners: [
                {
                    username: '',
                    percentage: '',
                    key: Date.now()
                }
                ]
            },
            admin_token:''
        }
        
    },
    created() {
         this.getList()
    },
    mounted(){
        this.admin_token = window.sessionStorage.getItem('admin_token') 
    },
    
    methods:{
        async getList() {
            const { data:res } = await this.$http.get('storelist/',{ params: this.queryInfo })
            if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
            console.log(this.list);
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
        },
        handleCurrentChange(newPage){
            // console.log(newPage)
            this.queryInfo.pagenum = newPage
            this.getList()
        },
        addDialogClosed(){
                this.$refs.addFormRef.resetFields()
        },
        AddStore(){
                this.$router.push({path: '/storeadd'})
        },
        async EditStore(id){
            this.$router.push({ path: `/storeedit/${id}` })
        },
        MarkStore(id){
            this.$router.push({ path: `/storemark/${id}` })
        },
        async DelStore(id){
            const confirmResult = await this.$confirm('是否要删除此门店吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err)

                if (confirmResult !== 'confirm'){
                    return this.$message.info('已取消删除！')
                }
                const { data: res } = await this.$http.get('/storedel/'+ id)
            
                if (res.status === 0) { 
                    this.$message({message: '删除成功！',type: 'success'})
                }
                this.getList()
        },
        async SetBoss(storeid){
            // console.log(storeid);
            const { data: res } = await this.$http.get('getboss/'+storeid)
            if(res.status == 0){
                this.dynamicValidateForm.owners = res.data.length ? res.data : [{ username: '' }];
            }
            this.setDialogVisible = true
            this.storeid = storeid
        },
        setDialogClosed(){
            this.setDialogVisible = false
        },
        async Setting(id){
                const { data: res } = await this.$http.get('storeinfo/' + id )
                console.log(res);
                this.editForm = res
                this.editForm.admin_token = this.admin_token
                this.settingDialogVisible = true
        },
        settingDialogClosed(){
            this.settingDialogVisible = false
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    console.log();
                    const usernames = this.dynamicValidateForm.owners.map(item => item.username);//取出username形成一个新数组
                    const hasDuplicates = (arr) => {//判断有没有重复
                        return arr.some((item, index) => arr.indexOf(item) !== index);
                    };
                    if(hasDuplicates(usernames)){
                        this.$message({message: '请勿重复设置店主',type: 'error'})
                        return
                    }
                    const { data: res } = await this.$http.post('setboss/',{storeid:this.storeid,formdata:usernames,admin_token:this.admin_token})
                    if(res.status == 0){
                        this.$message({message: '设置成功！',type: 'success'})
                        this.setDialogVisible = false
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        removeOwner(item) {
            console.log(this.dynamicValidateForm.owners.length == 1);
            const index = this.dynamicValidateForm.owners.indexOf(item);
            if (this.dynamicValidateForm.owners.length > 1 && index !== -1) {
                this.dynamicValidateForm.owners.splice(index, 1);
            }
        },
        addOwner() {
            this.dynamicValidateForm.owners.push({
                username: '',
                percentage: '',
                key: Date.now()
            })
        },
        async ChangeSub(e) {
            const confirmResult = await this.$confirm('危险操作：确定要切换商户收款吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err);

            if (confirmResult !== 'confirm') {
                if (e == "0"){
                    this.editForm.issub = "1"; // 恢复之前的状态
                }else{
                    this.editForm.issub = "0"; // 恢复之前的状态
                }
                return this.$message.info('已取消！');
            }
        },
        SettingSave(){
            this.$refs.editFormRef.validate( async valid=>{
                if (!valid) return
                const confirmResult = await this.$confirm('请再次确认收款商户！！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err);

                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消！');
                }
                const { data:res } = await this.$http.post('setstorepoints/',this.editForm)
                if(res.status===0)
                {
                    this.$message({message: '设置成功！',type: 'success'})
                }
                this.settingDialogVisible = false

            })
        },
        async SetMock(storeid,ismock){
            const { data:res } = await this.$http.post('setmock/',{storeid:storeid,ismock:ismock})
            if(res.status===0)
            {
                this.$message({message: '设置成功！',type: 'success'})
                this.getList()
            } 
        },
        // 生成签名的函数
        async getSign(signKey, data) {
        // 排序数据键
        const sortedKeys = Object.keys(data).sort();
        let stringSign = signKey;

        // 拼接键值对
        sortedKeys.forEach((key) => {
            const value = data[key];
            if (value) {
            stringSign += key + value;
            }
        });

        // 使用SHA-1生成签名
        const encoder = new TextEncoder();
        const dataBuffer = encoder.encode(stringSign);
        const hashBuffer = await crypto.subtle.digest('SHA-1', dataBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
        return hashHex;
        },
        async SetMeiTuan(storeid) {
            const timestamp = Date.now();
            const signKey = 'u7hjmpmxc5gjognt';
            const data = {
                businessId: '58',
                charset: 'UTF-8',
                developerId: '114173',
                state:storeid,
                timestamp: timestamp,
            };

            const sign = await this.getSign(signKey, data);
            const url = `https://open-erp.meituan.com/general/auth?developerId=114173&timestamp=${timestamp}&charset=UTF-8&businessId=58&sign=${sign}&state=${storeid}`;

            // 打开新窗口
            const newWindow = window.open(url, '_blank');

            if (!newWindow) {
                console.error('无法打开新窗口，请检查浏览器是否阻止弹窗。');
                return;
            }
        },
        async SetBath(){
            this.bathDialogVisible = true
            const { data:res } = await this.$http.get('setbath/')
            if(res.status===0)
            {
                this.bathlist = res.data
            } 
        },
        async AddBath(){
            if(this.bath_username.trim()==''){
                this.$message.error('请输入淋浴手机号')
            }else{
                const { data:res } = await this.$http.post('addbath/',{username:this.bath_username,admin_token:this.admin_token})
                if(res.status===0){
                    this.$message({message: '设置成功！',type: 'success'})
                }else{
                    this.$message.error(res.message)
                }
                this.bath_username = ''
                this.SetBath()
            }
        },
        async DelBath(username){
            const confirmResult = await this.$confirm('确定要删除此手机号吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).catch(err => err);

            if (confirmResult !== 'confirm') {
                return this.$message.info('已取消！');
            }
            const { data:res } = await this.$http.post('delbath/',{username:username,admin_token:this.admin_token})
            if(res.status===0)
            {
                this.$message({message: '删除成功',type: 'success'})
            }
            this.SetBath()
        }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>